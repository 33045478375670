
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { ProjectUser } from '@/modules/projects/types/entities';
import { DonutChartTooltip } from '../project-users-donut-chart/project-users-donut-chart.vue';
import { getColorPalette, getColorValue } from "@/modules/settings/views/workflow/helpers/workflow-helpers";
import { colorPalette } from "../../helpers";

@Component({})
export default class ProjectResponsibilityRainbowList extends Vue {
    @Prop({ default: []})
    list!: ProjectUser[];

    @Prop({ default: '' })
    startColor!: string;

    @Prop()
    tooltip!: DonutChartTooltip;

    getColor(color: string) {
		return getColorValue(color);
	}

    getColorPalette(length: number, startsFrom?: string): string[] {
	    const palette: string[] = getColorPalette();
		let index: number = startsFrom
			? palette.findIndex((colour: string) => colour === startsFrom)
			: 0;
		const newPalette: string[] = [];

        for (let i = 0; i < length; i++) {
			newPalette.push(palette[index]);

            index = index + 1 === palette.length
				? 0
				: index + 1;
		}
		return newPalette;
    }

    getChartData(): DonutChartTooltip[] {
		return this.list.map((item: ProjectUser, index: number) => {
			return {
				label: item.alias,
				value: item.workPercentage || 0,
				color: this.chartColorSet(this.list.length, this.startColor)[index] || 'gray'
			};
		});
    }
    
    chartColorSet(length: number, startsFrom?: string): string[] {
		return this.getColorPalette(length, startsFrom)
			.map((colorKey: string) => getColorValue(colorKey))
			.map((colorKey: string) => this.chartColor(colorKey));
    }

    chartColor(key: string, paletteIndex: number = 0): string {
        const palette = colorPalette()
            .filter((set: { key: string, value: string[] }) => key !== 'default');
        const defaultSet: { key: string, value: string[] } | undefined = colorPalette()
            .find((set: { key: string, value: string[] }) => key === 'default');
        const defaultColors: string[] = defaultSet
            ? defaultSet.value
            : ['#000', '#000'];

        const selectedPalette = palette
            .find((colorSet: { key: string, value: string[]}) => colorSet.key === key);

		return selectedPalette
			? selectedPalette.value[paletteIndex]
			: defaultColors[paletteIndex];
    }

    isSelected(item: DonutChartTooltip) {
        return item.label === this.tooltip.label
            && item.value === this.tooltip.value
            && item.color === this.tooltip.color;
    }
}
