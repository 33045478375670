
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { ProjectUser } from '@/modules/projects/types/entities';
	import { PropType } from 'vue';

	@Component
	export default class ProjectResponsibilitySelectComponent extends Vue {
		@Prop({ type: Array as PropType<ProjectUser[]>, default: [] }) users!: ProjectUser[];
		@Prop({ type: Array as PropType<ProjectUser[]>, default: [] }) list!: ProjectUser[];
		@Prop({ type: Array }) validationError!: any[];

		lastCalculatedPercentage = 0;

		get filteredUsers(): ProjectUser[] {
			return [...this.users.filter(x => !this.list.some(y => y.id === x.id))];
		}

		onRemoveUser(idx: number): void {
			if (idx >= 0) {
				this.list.splice(idx, 1);
				this.recalculateListPercentages();
			}
		}

		onAddUser(): void {
			if (this.filteredUsers[0]) {
				this.filteredUsers[0].workPercentage = 0;
				this.list.push(this.filteredUsers[0]);
			}
			this.recalculateListPercentages();
		}

		onChange(event: ProjectUser): void {
			if (this.lastCalculatedPercentage) {
				event.workPercentage = this.lastCalculatedPercentage;
			}
		}

		recalculateListPercentages(): void {
			this.list.forEach((x: ProjectUser, idx: number) => {
				if (!idx) {
					x.workPercentage = Math.floor(100 / this.list.length) + Math.floor( 100 % this.list.length)
				} else {
					x.workPercentage = Math.floor(100 / this.list.length);
				}
				if (idx + 1 === this.list.length) this.lastCalculatedPercentage = Math.floor(100 / this.list.length);
			})
		}
	}
