
	import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
	import { PropType } from 'vue';
	import { Project, ProjectUser } from '@/modules/projects/types/entities';
  import ProjectResponsibilitySelect from '@/modules/projects/components/project-responsibility-select/project-responsibility-select.vue';
  import ProjectUserDonutChart from '@/modules/projects/components/project-users-donut-chart/project-users-donut-chart.vue';
  import ProjectResponsibilityRainbowList from '@/modules/projects/components/project-responsibility-rainbow-list/project-responsibility-rainbow-list.vue';

	@Component({
		components: {
			'project-responsibility-select': ProjectResponsibilitySelect,
			'project-user-donut-chart': ProjectUserDonutChart,
			'project-responsibility-rainbow-list': ProjectResponsibilityRainbowList,
		}
	})
	export default class ProjectResponsibilitySectionComponent extends Vue {
		@PropSync('project', { type: Object as PropType<Project>, required: true }) projectSync!: Project;
		@PropSync('editResponsibleGroups', { type: Boolean, default: false }) editResponsibleGroupsSync!: boolean;
		@Prop({ type: Object, required: false }) validationErrors!: any;
		@Prop({ type: Array as PropType<ProjectUser[]>, default: [] }) users!: ProjectUser[];

		state = {
			responsiblityShowMore: {
				estimators: false,
				projectLeaders: false,
				drawers: false,
				designers: false
			},
			responsibilityValidatorList: {
				estimators: true,
				projectLeaders: true,
				drawers: true,
				designers: true
			},
		};

		chartPopupDetails = {
			label: '',
			value: 0,
			color: ''
		};

		onHoveredChartTooltip(section: { label: string, value: number, color: string }): void {
			this.chartPopupDetails = section;
		}
	}
