export function colorPalette(): Array<{ key: string; value: string[] }> {
    return [
        { key: 'blue-one', value:     ['#93D5FA', '#7AB3D3'] },
        { key: 'blue-two', value:     ['#4488AD', '#326886'] },
        { key: 'blue-three', value:   ['#4F9EE8', '#4888C4'] },
        { key: 'blue-four', value:    ['#149DFF', '#1383D2'] },
        { key: 'blue-five', value:    ['#93D5FA', '#76ADCB'] },
        { key: 'blue-six', value:     ['#4488AD', '#3C7797'] },
        { key: 'blue-seven', value:   ['#B5F5F5', '#8DC9C9'] },
        { key: 'blue-eight', value:   ['#1BD3F5', '#15B1CE'] },
        { key: 'blue-nine', value:    ['#1477EB', '#1263C2'] },
        { key: 'green-one', value:    ['#03D7BF', '#00A884'] },
        { key: 'green-two', value:    ['#97FBE5', '#78CBBA'] },
        { key: 'green-three', value:  ['#03D797', '#04A373'] },
        { key: 'green-four', value:   ['#C8FAEE', '#A8D0C7'] },
        { key: 'gray-one', value:     ['#DADAE1', '#AAACBB'] },
        { key: 'gray-two', value:     ['#EEF0FA', '#C3C4CC'] },
        { key: 'gray-three', value:   ['#BFD2CB', '#91A29B'] },
        { key: 'gray-four', value:    ['#B9CDB8', '#899D88'] },
        { key: 'purple-one', value:   ['#AF7AFF', '#bf95ff'] },
        { key: 'purple-two', value:   ['#796EFF', '#948bff'] },
        { key: 'purple-three', value: ['#678DFF', '#5976D0'] },
        { key: 'yellow-one', value:   ['#F4D03F', '#F6D965'] },
        { key: 'yellow-two', value:   ['#B7950B', '#c5aa3c'] },
        { key: 'red-one', value:      ['#FF4B14', '#DD3C0B'] },
        { key: 'red-two', value:      ['#FF2445', '#CB0808'] },
        { key: 'red-three', value:    ['#C7202E', '#941A25'] },
        { key: 'pink-one', value:     ['#FAD4C8', '#CDADA3'] },
        { key: 'pink-two', value:     ['#EA8679', '#B66B61'] },
        { key: 'orange-one', value:   ['#FEAA00', '#CE8A01'] },
        { key: 'orange-two', value:   ['#FE8200', '#D56D00'] },
        { key: 'orange-three', value: ['#FACE93', '#CDA978'] },
        { key: 'orange-four', value:  ['#FA9E7D', '#CE7F63'] },
        { key: 'default', value:      ['#DADAE1', '#AAACBB'] }
    ];
}
