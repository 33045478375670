var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"details-page__container",class:[{ 'active': _vm.editResponsibleGroupsSync }],staticStyle:{"margin":"15px 25px","max-width":"95%"}},[_c('div',{staticClass:"details-page__header"},[_c('div',{class:['details-page__responsibility__header__container']},[_vm._v(" "+_vm._s(_vm.$t('projects.details.headings.responsibility'))+" "),(!_vm.editResponsibleGroupsSync)?[_c('button',{staticClass:"details-page__project__button-edit",on:{"click":function($event){_vm.editResponsibleGroupsSync = true}}},[_c('img',{attrs:{"src":require("@/assets/edit-button.svg"),"alt":"edit icon"}})])]:[_c('button',{staticClass:"details-page__buttons__action--small-submit ml-auto mr-1",on:{"click":function($event){return _vm.$emit('editProjectResponsibleGroups')}}},[_c('img',{attrs:{"src":require("@/assets/action_success_blue.svg"),"alt":"submit icon"}})]),_c('button',{staticClass:"details-page__buttons__action--small-decline",on:{"click":function($event){_vm.editResponsibleGroupsSync = false}}},[_c('img',{attrs:{"src":require("@/assets/action_plus.svg"),"alt":"cancel icon"}})])]],2)]),(_vm.projectSync)?_c('v-row',{staticClass:"details-page__responsibility mx-0"},[_c('v-col',{attrs:{"sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('div',{staticClass:"details-page__responsibility__heading",class:{
													'details-page__responsibility__heading--clickable': _vm.projectSync.projectLeaders.length > 3
												},on:{"click":function($event){_vm.state.responsiblityShowMore.projectLeaders = !_vm.state.responsiblityShowMore.projectLeaders}}},[_vm._v(" "+_vm._s(_vm.$t('projects.details.labels.projectLeader', { count: _vm.projectSync.projectLeaders.length }))+" "),(_vm.projectSync.projectLeaders.length > 3 && !_vm.editResponsibleGroupsSync)?_c('img',{class:{
														'rotate': _vm.state.responsiblityShowMore.projectLeaders
													},attrs:{"src":require("@/assets/chevron.svg")}}):_vm._e()]),(!_vm.editResponsibleGroupsSync)?_c('div',[(_vm.projectSync.projectLeaders.length > 0)?_c('div',{staticClass:"details-page__responsibility__chart__container"},[_c('project-user-donut-chart',{attrs:{"data":_vm.projectSync.projectLeaders,"startColor":"BLUE_1"},on:{"hovered":_vm.onHoveredChartTooltip}})],1):_vm._e(),(_vm.projectSync.projectLeaders.length > 0)?[_c('project-responsibility-rainbow-list',{attrs:{"list":_vm.projectSync.projectLeaders
															.slice(0, _vm.state.responsiblityShowMore.projectLeaders ? _vm.projectSync.projectLeaders.length : 3),"startColor":"BLUE_1","tooltip":_vm.chartPopupDetails}})]:[_c('div',{staticClass:"details-page__responsibility__empty"},[_vm._v(" "+_vm._s(_vm.$t(`projects.details.attributes.noProjectLeaders`))+" ")])]],2):_c('div',{staticStyle:{"position":"relative","left":"10px"}},[_c('project-responsibility-select',{attrs:{"users":_vm.users,"list":_vm.projectSync.projectLeaders,"validationError":_vm.validationErrors && _vm.validationErrors.projectLeaders}})],1)]),_c('v-col',{attrs:{"sm":"3"}},[_c('div',{staticClass:"details-page__responsibility__heading",class:{
													'details-page__responsibility__heading--clickable': _vm.projectSync.estimators.length > 3
												},on:{"click":function($event){_vm.state.responsiblityShowMore.estimators = !_vm.state.responsiblityShowMore.estimators}}},[_vm._v(" "+_vm._s(_vm.$t('projects.details.labels.calculator', { count: _vm.projectSync.estimators.length }))+" "),(_vm.projectSync.estimators.length > 3 && !_vm.editResponsibleGroupsSync)?_c('img',{class:{
														'rotate': _vm.state.responsiblityShowMore.estimators
													},attrs:{"src":require("@/assets/chevron.svg")}}):_vm._e()]),(!_vm.editResponsibleGroupsSync)?_c('div',[(_vm.projectSync.estimators.length > 0)?_c('div',{staticClass:"details-page__responsibility__chart__container"},[_c('project-user-donut-chart',{attrs:{"data":_vm.projectSync.estimators,"startColor":"BLUE_1"},on:{"hovered":_vm.onHoveredChartTooltip}})],1):_vm._e(),(_vm.projectSync.estimators.length > 0)?[_c('project-responsibility-rainbow-list',{attrs:{"list":_vm.projectSync.estimators
															.slice(0, _vm.state.responsiblityShowMore.estimators ? _vm.projectSync.estimators.length : 3),"startColor":"BLUE_1","tooltip":_vm.chartPopupDetails}})]:[_c('div',{staticClass:"details-page__responsibility__empty"},[_vm._v(" "+_vm._s(_vm.$t(`projects.details.attributes.noEstimators`))+" ")])]],2):_c('div',{staticStyle:{"position":"relative","left":"10px"}},[_c('project-responsibility-select',{attrs:{"users":_vm.users,"list":_vm.projectSync.estimators,"validationError":_vm.validationErrors && _vm.validationErrors.estimators}})],1)]),_c('v-col',{attrs:{"sm":"3"}},[_c('div',{staticClass:"details-page__responsibility__heading",class:{
													'details-page__responsibility__heading--clickable': _vm.projectSync.designers.length > 3
												},on:{"click":function($event){_vm.state.responsiblityShowMore.designers = !_vm.state.responsiblityShowMore.designers}}},[_vm._v(" "+_vm._s(_vm.$t('projects.details.labels.designer', { count: _vm.projectSync.designers.length }))+" "),(_vm.projectSync.designers.length > 3 && !_vm.editResponsibleGroupsSync)?_c('img',{class:{
														'rotate': _vm.state.responsiblityShowMore.designers
													},attrs:{"src":require("@/assets/chevron.svg")}}):_vm._e()]),(!_vm.editResponsibleGroupsSync)?_c('div',[(_vm.projectSync.designers.length > 0)?_c('div',{staticClass:"details-page__responsibility__chart__container"},[_c('project-user-donut-chart',{attrs:{"data":_vm.projectSync.designers,"startColor":"BLUE_1"},on:{"hovered":_vm.onHoveredChartTooltip}})],1):_vm._e(),(_vm.projectSync.designers.length > 0)?[_c('project-responsibility-rainbow-list',{attrs:{"list":_vm.projectSync.designers
															.slice(0, _vm.state.responsiblityShowMore.designers ? _vm.projectSync.designers.length : 3),"startColor":"BLUE_1","tooltip":_vm.chartPopupDetails}})]:[_c('div',{staticClass:"details-page__responsibility__empty"},[_vm._v(" "+_vm._s(_vm.$t(`projects.details.attributes.noDesigners`))+" ")])]],2):_c('div',{staticStyle:{"position":"relative","left":"10px"}},[_c('project-responsibility-select',{attrs:{"users":_vm.users,"list":_vm.projectSync.designers,"validationError":_vm.validationErrors && _vm.validationErrors.designers}})],1)]),_c('v-col',{attrs:{"sm":"3"}},[_c('div',{staticClass:"details-page__responsibility__heading",class:{ 'details-page__responsibility__heading--clickable': _vm.projectSync.drawers.length > 3 },on:{"click":function($event){_vm.state.responsiblityShowMore.drawers = !_vm.state.responsiblityShowMore.drawers}}},[_vm._v(" "+_vm._s(_vm.$t('projects.details.labels.drawers', { count: _vm.projectSync.drawers.length }))+" "),(_vm.projectSync.drawers.length > 3 && !_vm.editResponsibleGroupsSync)?_c('img',{class:{
														'rotate': _vm.state.responsiblityShowMore.drawers
													},attrs:{"src":require("@/assets/chevron.svg")}}):_vm._e()]),(!_vm.editResponsibleGroupsSync)?_c('div',[(_vm.projectSync.drawers.length > 0)?_c('div',{staticClass:"details-page__responsibility__chart__container"},[_c('project-user-donut-chart',{attrs:{"data":_vm.projectSync.drawers,"startColor":"BLUE_1"},on:{"hovered":_vm.onHoveredChartTooltip}})],1):_vm._e(),(_vm.projectSync.drawers.length > 0)?[_c('project-responsibility-rainbow-list',{attrs:{"list":_vm.projectSync.drawers
															.slice(0, _vm.state.responsiblityShowMore.drawers ? _vm.projectSync.drawers.length : 3),"startColor":"BLUE_1","tooltip":_vm.chartPopupDetails}})]:[_c('div',{staticClass:"details-page__responsibility__empty"},[_vm._v(" "+_vm._s(_vm.$t(`projects.details.attributes.noDrawers`))+" ")])]],2):_c('div',{staticStyle:{"position":"relative","left":"10px"}},[_c('project-responsibility-select',{attrs:{"users":_vm.users,"list":_vm.projectSync.drawers,"validationError":_vm.validationErrors && _vm.validationErrors.drawers}})],1)])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }