
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { ProjectUser } from '@/modules/projects/types/entities';
import { getColorPalette, getColorValue } from "@/modules/settings/views/workflow/helpers/workflow-helpers";
import { colorPalette } from "../../helpers";

export interface DonutChartTooltip {
    label: string;
    value: number;
    color: string;
}

export interface DonutChartCoordinates {
    x: number;
    y: number;
}

@Component({})
export default class ProjectUsersDonutChartComponent extends Vue {
    @Prop({ default: [] })
    data!: ProjectUser[];

    @Prop({ default: '' })
    startColor!: string;

    isDisplayTooltip: boolean = false;
    tooltipCoordinates: DonutChartCoordinates = { x: 0, y: 0 };
    tooltip: DonutChartTooltip = {
        label: '',
        value: 0,
        color: ''
    };

    showTooltip(section: DonutChartTooltip, $event: MouseEvent): void {
		this.isDisplayTooltip = true;
		this.tooltipCoordinates = {
			x: $event.x,
			y: $event.y
		};
        this.tooltip = section;
        
        this.$emit('hovered', section);
    }
    
    hideTooltip(section: DonutChartTooltip, $event: any): void {
        if ($event.toElement && $event.toElement.classList && $event.toElement.classList.contains('cdc-filler')) {
            return;
        }
    
        this.isDisplayTooltip = false;
        this.$emit('hovered', {
            label: '',
            value: 0,
            color: ''
        });
    }

    getChartData(): DonutChartTooltip[] {
		return this.data.map((item: ProjectUser, index: number) => {
			return {
				label: item.alias,
				value: item.workPercentage || 0,
				color: this.chartColorSet(this.data.length, this.startColor)[index] || 'gray'
			};
		});
	}

    chartColor(key: string, paletteIndex: number = 0): string {
        const palette = colorPalette()
            .filter((set: { key: string, value: string[] }) => key !== 'default');
        const defaultSet: { key: string, value: string[] } | undefined = colorPalette()
            .find((set: { key: string, value: string[] }) => key === 'default');
        const defaultColors: string[] = defaultSet
            ? defaultSet.value
            : ['#000', '#000'];

		const selectedPalette = palette
			.find((colorSet: { key: string, value: string[]}) => colorSet.key === key);

		return selectedPalette
			? selectedPalette.value[paletteIndex]
			: defaultColors[paletteIndex];
    }

    getColorPalette(length: number, startsFrom?: string): string[] {
		const palette: string[] = getColorPalette();
		let index: number = startsFrom
			? palette.findIndex((colour: string) => colour === startsFrom)
			: 0;
		const newPalette: string[] = [];

        for (let i = 0; i < length; i++) {
			newPalette.push(palette[index]);

            index = index + 1 === palette.length
				? 0
				: index + 1;
		}
		return newPalette;
    }
    
    chartColorSet(length: number, startsFrom?: string): string[] {
		return this.getColorPalette(length, startsFrom)
			.map((colorKey: string) => getColorValue(colorKey))
			.map((colorKey: string) => this.chartColor(colorKey));
    }

    getTotal(): number {
        return this.data
            .map((item: ProjectUser) => item.workPercentage || 0)
            .reduce((prev: number, next: number) => prev + next);
    }
}
